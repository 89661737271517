import React from "react";
import {DropzoneArea} from "material-ui-dropzone";
import {makeStyles} from "@material-ui/core/styles";
import TutorialGrid from "./TutorialGrid";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    cardsGrid: {
        marginTop: theme.spacing(1)
    },
}))

const CreateNewImageTemplateTab = (props) => {
    const classes = useStyles()

    return (
        <div
            className={classes.root}
            hidden={props.hidden}>
            <DropzoneArea
                onChange={props.handle_drop_zone_change}
                acceptedFiles={['image/svg+xml']}
                maxFileSize={5e+6}
                filesLimit={1}
                dropzoneText={"Drag and Drop Your SVG File Here"} />
            <TutorialGrid styleClasses={classes.cardsGrid} />
        </div>
    )
}

export default CreateNewImageTemplateTab
