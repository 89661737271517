import Dialog from "@material-ui/core/Dialog";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles((theme) => ({
    modalC: {
        padding: theme.spacing(4)
    }
}))

const DyProgressBar = (props) => {

    const classes = useStyles()

    return (
        <Dialog
            disableScrollLock
            disableBackdropClick
            disableEscapeKeyDown
            className={classes.modalC}
            open={props.loadingOpen}
            onClose={props.toggle}>
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    );
}

export default DyProgressBar
