import {Button, Grid, Modal} from "@material-ui/core";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const LoginModal = (props) => {
    return (
        <Dialog
            open={props.loginModal}
            onClose={props.close}>
            <DialogTitle>
                Login to Dynamage
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    direction={"column"}
                    alignItems={"center"}
                    justify={"center"}>
                    <Grid
                        item>
                        <TextField
                            label={"Email"}
                            variant={"outlined"}
                            value={props.email}
                            onChange={props.emailChange}
                            placeholder={"Enter Your Email"}/>
                    </Grid>
                    <Grid
                        item>
                        <TextField
                            type={'password'}
                            label={"Password"}
                            variant={"outlined"}
                            value={props.password}
                            onChange={props.passwordChange}
                            placeholder={"Enter Your Password"}/>
                    </Grid>
                    <Grid
                        item>
                        <Button
                            onClick={props.signInBtnClick}
                            color={"secondary"}
                            variant={"contained"}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default LoginModal
