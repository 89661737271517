import {Grid} from "@material-ui/core";
import MediaCard from "./MediaCard";
import React from "react";

const TutorialGrid = (props) => {
    return (
        <Grid
            className={props.styleClasses}
            container
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            justify={"center"}>
            <Grid
                item>
                <MediaCard
                    image={"/images/step1.JPG"}
                    title={"Create Image Template in SVG"}
                    description={"Create Image Template in your Desired SVG Editor like Adobe XD or online editors."} />
            </Grid>
            <Grid
                item>
                <MediaCard
                    image={"/images/step2.JPG"}
                    title={"Use above Variable's"}
                    description={"While Editing SVG Add Above Variable's ($%var1%-$%var9%) in Text Where you want to show your dynamic text."} />
            </Grid>
            <Grid
                item>
                <MediaCard
                    image={"/images/step3.png"}
                    title={"Export SVG and Upload"}
                    description={"After you done With Template design Export it into .SVG and upload it On our site.\n" +
                    "\n" +
                    "Now You can use your generated APi anywhere you want"} />
            </Grid>
        </Grid>
    )
}

export default TutorialGrid
