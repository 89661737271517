import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    },
    crp: {
        marginTop: "10%",
        marginLeft: "50%"
    }
}));

export default function DyCircularProgress() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress className={classes.crp} />
        </div>
    );
}
