import './App.css';
import worker from './worker.js';
import WebWorker from './workerSetup';
import React, {Component} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import LoginModal from "./components/LoginModal";
import SignUpModal from "./components/SignUpModal";
import Cookies from 'universal-cookie';
import ReactDOM from "react-dom";
import Dashboard from "./components/Dashboard";
import TutorialGrid from "./components/TutorialGrid";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    cardsGrid: {
        marginTop: theme.spacing(1)
    },
    lgSpBtnGroup: {
        marginTop: theme.spacing(1),
        marginRight: "auto",
        marginLeft: "auto",
    }
}));

class App extends Component {

    state = {
        login_modal: false,
        sign_up_modal: false,
        name: '',
        email: '',
        password: '',
        re_password: '',
    }

    componentDidMount = () => {
        const cookies = new Cookies();

        if(cookies.get('jwt_session')){
            ReactDOM.render(
                <Dashboard />,
                document.getElementById('root')
            );
        }

        this.worker = new WebWorker(worker);
        this.worker.addEventListener('message', event => {
            if(event.data.task === 'signup' && event.data.status === 200)
            {
                alert("Your Account Created Successfully");
                this.setState({
                    re_password: '',
                    login_modal: true,
                    sign_up_modal: false,
                })
            }
            else if(event.data.task === 'sign_in' && event.data.status === 200)
            {
                cookies.set('jwt_session', event.data.data.authtoken , { path: '/', maxAge: '2.592e+6' });
                console.log(cookies.get('jwt_session'));
                console.log(event.data);
                alert("You Have Been Signed In Successfully")
                ReactDOM.render(
                    <Dashboard />,
                    document.getElementById('root')
                );
            }
            else
                alert(event.data)

        });
    }

    render() {

        const { classes } = this.props

        const toggleLoginModal = () => {
            this.setState({
                login_modal: !this.state.login_modal
            })
        }

        const toggleSignUpModal = () => {
            this.setState({
                sign_up_modal: !this.state.sign_up_modal
            })
        }

        const handleNameTextChange = (event) => {
            this.setState({
                name: event.target.value
            })
        }

        const handleEmailTextChange = (event) => {
            this.setState({
                email: event.target.value
            })
        }

        const handlePasswordTextChange = (event) => {
            this.setState({
                password: event.target.value
            })
        }

        const handleRePasswordTextChange = (event) => {
            this.setState({
                re_password: event.target.value
            })
        }

        const handleSignUpBtnClick = (e) => {
            console.log("clicked")
            e.preventDefault()
            if(this.state.email === '') {
                alert('Email is not filled')
            }
            else if(this.state.password === '') {
                alert('Password is not filled')
            }
            else if(this.state.re_password === '') {
                alert('Re Password is not filled')
            }
            else if(this.state.password !== this.state.re_password) {
                alert('Password and Re Password must be same')
            }
            else
            {
                console.log("worker creater")
                this.worker.postMessage({
                    task: 'signup',
                    data: {
                        name: this.state.name,
                        email: this.state.email,
                        password: this.state.password
                    }
                });
            }
        }

        const handleSignInBtnClick = () => {
            if(this.state.email === '') {
                alert('Email is not filled')
            }
            else if(this.state.password === '') {
                alert('Password is not filled')
            }
            else
            {
                this.worker.postMessage({
                    task: 'sign_in',
                    data: {
                        email: this.state.email,
                        password: this.state.password
                    }
                });
            }
        }

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>*/}
                        <Typography variant="h6" className={classes.title}>
                            Dynamage
                        </Typography>
                    </Toolbar>
                </AppBar>

                <LoginModal
                    loginModal={this.state.login_modal}
                    close={toggleLoginModal}
                    email={this.state.email}
                    password={this.state.password}
                    emailChange={handleEmailTextChange}
                    passwordChange={handlePasswordTextChange}
                    signInBtnClick={handleSignInBtnClick} />
                <SignUpModal
                    signUpModal={this.state.sign_up_modal}
                    close={toggleSignUpModal}
                    email={this.state.email}
                    password={this.state.password}
                    re_password={this.state.re_password}
                    emailChange={handleEmailTextChange}
                    passwordChange={handlePasswordTextChange}
                    re_passwordChange={handleRePasswordTextChange}
                    signUpBtnClick={handleSignUpBtnClick}
                    nameChange={handleNameTextChange} />

                <Grid
                    container
                    alignItems={"center"}
                    justify={"center"}>
                    <Grid
                        item>
                        <ButtonGroup
                            className={classes.lgSpBtnGroup}
                            variant="contained"
                            color="primary"
                            aria-label="contained primary button group">
                            <Button onClick={toggleLoginModal}>Log In</Button>
                            <Button onClick={toggleSignUpModal}>Sign Up</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>

                    <TutorialGrid styleClasses={classes.cardsGrid} />
            </div>
        )
    }
}

export default withStyles(useStyles)(App);
