export default () => {
    console.log("worker started")
    const config_url = {
        domain: 'https://dynamage-test.herokuapp.com/',
    };

    const xmlhttp = new XMLHttpRequest();   // new HttpRequest instance

    self.addEventListener('message', (e) => { // eslint-disable-line no-restricted-globals
        if (!e) return;
        console.log("worker listener started")

        const users = [];
        console.log("Derp: ",e.data);

        if(e.data.task === 'signup')
        {
            const theUrl = config_url.domain + "crud/registerUser";

            /*xmlhttp.addEventListener("readystatechange", function() {
                if(this.readyState === 4) {
                    console.log(this.responseText);

                }
            });*/
            let flag=0
            xmlhttp.onreadystatechange = function() {
                console.log("state changed called")
                if (this.readyState === 4 && this.status === 200 && flag ===0) {
                    flag+=1
                    postMessage({
                        status: 200,
                        data: this.response,
                        task: 'signup'
                    });
                }
                else if (this.readyState === 4  && this.status !== 200 && flag ===0)
                {
                    console.log("State Change Executed")
                    //console.log("lol"+this.response)
                    flag+=1
                    postMessage(this.responseText)
                }
                console.log(flag)
            };

            xmlhttp.open("POST", theUrl);
            xmlhttp.setRequestHeader("Content-Type", "application/json");
            xmlhttp.send(JSON.stringify({
                "name": e.data.data.name,
                "email": e.data.data.email,
                "password": e.data.data.password,
            }));

        }
        else if(e.data.task === 'sign_in')
        {
            const theUrl = config_url.domain + "crud/loginUser";

            /*xmlhttp.addEventListener("readystatechange", function() {
                if(this.readyState === 4) {
                    console.log(this.responseText);

                }
            });*/
            let flag=0
            xmlhttp.onreadystatechange = function() {
                console.log("state changed called")
                if (this.readyState === 4 && this.status === 200 && flag ===0) {
                    flag+=1
                    const res = JSON.parse(this.response)
                    postMessage({
                        status: 200,
                        data: res,
                        task: 'sign_in'
                    });
                }
                else if (this.readyState === 4  && this.status !== 200 && flag ===0)
                {
                    console.log("State Change Executed")
                    //console.log("lol"+this.response)
                    flag+=1
                    postMessage(this.responseText)
                }
                console.log(flag)
            };

            xmlhttp.open("POST", theUrl);
            xmlhttp.setRequestHeader("Content-Type", "application/json");
            xmlhttp.send(JSON.stringify({
                "email": e.data.data.email,
                "password": e.data.data.password,
            }));
        }
        // Comment because this code is not stable
        else if(e.data.task === 'upload_image')
        {
            console.log("Upload Web Worker")
            const xhr = new XMLHttpRequest();
            //xhr.withCredentials = true;

            const jsn = e.data
            console.log("Token: "+jsn)
            const data = new FormData();
            data.append("imageFile", jsn.file, jsn.file.path);

            xhr.addEventListener("readystatechange", function() {
                if(this.readyState === 4 && this.status === 200) {
                    console.log(this.responseText);
                    postMessage({
                        message: 'Image Uploaded Successfully',
                        status: 200,
                        image: this.response,
                        task: 'upload_image'
                    })
                }
                else if(this.readyState === 4 && this.status !== 200){
                    postMessage({
                        message: this.responseText,
                        task: 'upload_image'
                    })
                }
            });

            xhr.open("PUT", config_url.domain + "crud/user/uploadImage");
            //xhr.open("PUT", "http://localhost:3000/crud/user/uploadImage");
            //xhr.setRequestHeader("authtoken", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZjljMjkxZjAxNjYzMjY0ODg3ZTA3MmIiLCJpYXQiOjE2MDQxMTY5NTJ9.XjnjFS0ct66htWG2lPXUSFFK-yzECBrxd_aOhA9tdeY");
            xhr.setRequestHeader("authtoken", jsn.token);
            //xhr.setRequestHeader('Content-Type', 'multipart/form-data');
            xhr.send(data);
        }
        else if(e.data.task === 'fetchImages')
        {
            const data = "";

            const xhr = new XMLHttpRequest();

            xhr.addEventListener("readystatechange", function() {
                if (this.readyState === 4 && this.status === 200) {
                    const res = JSON.parse(this.response)
                    console.log(res)
                    res.forEach((img) => {
                        postMessage({
                            status: 200,
                            image: img,
                            task: 'postImage'
                        });
                    })
                    if(res.length === 0)
                    {
                        postMessage({
                            status: 200,
                            task: 'postImage',
                            message: 'No Images Uploaded Yet'
                        });
                    }
                }
            });

            xhr.open("POST", "https://dynamage-test.herokuapp.com/crud/user/UserImages");
            xhr.setRequestHeader("authtoken", e.data.token);

            xhr.send(data);
        }
        else if(e.data.task === 'deleteImage'){
            const data = new FormData();

            const xhr = new XMLHttpRequest();

            xhr.addEventListener("readystatechange", function() {
                if(this.readyState === 4 && this.status === 200) {
                    const res = JSON.parse(this.response)
                    if(res.deletedCount === 1){
                        postMessage({
                            status: 200,
                            message: "Image Delete Successfully Done.",
                            task: 'deleteImage'
                        });
                    }
                    else
                    {
                        postMessage({
                            status: 400,
                            message: "Invalid Request",
                            task: 'deleteImage'
                        });
                    }
                }
            });

            xhr.open("DELETE", "https://dynamage-test.herokuapp.com/crud/user/deleteImage/"+e.data.img_id);
            xhr.setRequestHeader("authtoken", e.data.token);
            xhr.send(data);
        }
    })
}
