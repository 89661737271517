import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const tab_title_arr = [
    "Create New Image Template",
    "My Existing Image Template"
]

const DyTabs = (props) => {
    return (
        <div>
            <Tabs value={props.tab_value} onChange={props.handle_tab_change}>
                {
                    tab_title_arr.map((item, index) => (
                        <Tab key={index} label={item} />
                    ))
                }
            </Tabs>
        </div>
    )
}

export default DyTabs
