import React, {Component} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {withStyles} from "@material-ui/core/styles";
import DyTabs from "./DyTabs";
import CreateNewImageTemplateTab from "./CreateNewImageTemplateTab";
import worker from '../worker';
import WebWorker from '../workerSetup';
import Cookies from "universal-cookie";
import MyExistingImageTemplate from "./MyExistingImageTemplate";
import Button from "@material-ui/core/Button";
import {ExitToApp} from "@material-ui/icons";
import DyProgressBar from "./DyProgressBar";

const useStyles = ((theme) => ({
    title: {
        flexGrow: 1,
    }
}));

const cookies = new Cookies();
const tok = cookies.get('jwt_session')
let tmp_del;

class Dashboard extends Component {

    state = {
        tabValue: 0,
        file: [],
        userImages: [],
        noImages: false,
        loading: false,
    }

    componentDidMount() {
        this.worker = new WebWorker(worker);
        this.worker.postMessage({
            task: 'fetchImages',
            token: tok
        })
        this.worker.addEventListener('message', event => {
            if (event.data.task === 'postImage' && event.data.status === 200) {
                if(event.data.message)
                {
                    this.setState({
                        noImages: true
                    })
                }
                else
                {
                    console.log("Post Image: " + event.data.image)
                    const tmp = []
                    tmp.push(event.data.image)
                    this.setState({
                        userImages: this.state.userImages.concat(tmp)
                    })
                }
            }
            else if (event.data.task === 'deleteImage' && event.data.status === 200) {
                let tmp_arr = Object.assign(this.state.userImages)
                tmp_arr = tmp_arr.splice(0, tmp_del);
                this.setState({
                    userImages: tmp_arr
                })
                if(tmp_arr.length === 0){
                    this.setState({
                        noImages: true
                    })
                }
                alert(event.data.message)
            }
            else if (event.data.task === 'deleteImage' && event.data.status === 400) {
                alert(event.data.message)
            }
            else if (event.data.task === 'upload_image') {
                alert(event.data.message)
                if(event.data.status === 200)
                {
                    this.setState({
                        loading: false
                    })
                    window.location.reload()
                }
                else
                {
                    window.location.reload()
                }
            }
        })
    }

    handleChange(files) {
        this.setState({
            files: files
        });
    }

    render() {
        const {classes} = this.props

        const handleTabChange = (event, newVal) => {
            this.setState({
                tabValue: newVal
            })
        }

        const uploadImage = (files) => {
            const data = new FormData();
            data.append("imageFile", files[0], files[0].path);
            const dt = {
                task: 'upload_image',
                token: tok,
                file: files[0]
            }
            console.log(files[0])
            this.worker.postMessage({
                task: 'upload_image',
                token: tok,
                file: files[0]
            });
        }

        const handleDropZoneChange = (files) => {

            if (files.length === 0) {
                console.log("Init: DRP")
            } else {
                this.setState({
                    loading: true,
                    files: files
                });

                console.log(files)
                uploadImage(files)
            }
        }

        const handleLogOutBtnClick = () => {
            cookies.remove('jwt_session')
            window.location.reload()
        }

        const handleDelBtnClick = (item, index) => {
            tmp_del = index
            this.worker.postMessage({
                task: 'deleteImage',
                token: tok,
                img_id: item._id,
            })
        }

        const handleToggleLoading = () => {
            this.setState({
                loading: !this.state.loading
            })
        }

        return (
            <div>
                <DyProgressBar loadingOpen={this.state.loading} toggle={handleToggleLoading} />
                <AppBar position="static">
                    <Toolbar>
                        {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>*/}
                        <Typography variant="h6" className={classes.title}>
                            Dynamage
                        </Typography>
                        <Button
                            onClick={handleLogOutBtnClick}
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            endIcon={<ExitToApp/>}>
                            Logout
                        </Button>
                    </Toolbar>
                    <DyTabs tab_value={this.state.tabValue} handle_tab_change={handleTabChange}/>
                </AppBar>
                <CreateNewImageTemplateTab
                    hidden={!(this.state.tabValue === 0)}
                    drp_file={this.state.file}
                    handle_drop_zone_change={handleDropZoneChange}/>

                <MyExistingImageTemplate
                    hidden={!(this.state.tabValue === 1)}
                    imgArr={this.state.userImages}
                    delBtnClk={handleDelBtnClick}
                    noImg={this.state.noImages} />
            </div>
        )
    }
}

export default withStyles(useStyles)(Dashboard)
