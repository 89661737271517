import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Paper from "@material-ui/core/Paper";
import DyCircularProgress from "./DyCircularProgress";
import {Button, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    cardsGrid: {
        marginTop: theme.spacing(1),
        height: '300px'
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        display: "block"
    },
    delBtn: {
        background: '#F44336'
    }
}))

const MyExistingImageTemplate = (props) => {
    const classes = useStyles()

    return (
        <div
            className={classes.root}
            hidden={props.hidden}>
            {
                props.noImg ?
                    <Paper>
                        <Typography align={"center"}>
                            No Images Uploaded Yet
                        </Typography>
                    </Paper>
                    :
                    props.imgArr.length === 0 ?
                        <DyCircularProgress/>
                        :
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Name</TableCell>
                                        <TableCell align="center">Download Count</TableCell>
                                        <TableCell align="center">API URI</TableCell>
                                        <TableCell align="center">MODIFY</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.imgArr.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row">
                                                {item.imageName}
                                            </TableCell>
                                            <TableCell
                                                align="center">
                                                {item.downloadCount}
                                            </TableCell>
                                            <TableCell
                                                align="center">
                                                <TextField
                                                    disabled={true}
                                                    value={"https://api-dynamage.herokuapp.com/?id=" + item._id+"&height=200&width=200&var1=YourString1&var2=YourString2"}/>
                                            </TableCell>
                                            <TableCell
                                                align="center">
                                                <Button
                                                    className={classes.delBtn}
                                                    variant={"contained"}
                                                    onClick={() => {props.delBtnClk(item, index)}}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
            }
        </div>
    )
}

export default MyExistingImageTemplate
